<template>
  <div v-if="this.$route.name == 'Admin_Organisations'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Organisations', route: 'Admin_Organisations' },
      ]"
    />
    <div class="placeholder">
      <Admin_Organisations_ListDatatable />
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
import Admin_Organisations_ListDatatable from "@/components/Admin.Organisations.ListDatatable.vue";
import Navigation from "@/components/Navigation.vue";
export default {
  name: "Admin_Businesses",
  setup() {
    const store = useStore();

    return { store };
  },
  components: {
    Navigation,
    Admin_Organisations_ListDatatable,
  },
};
</script>

<style scoped>
</style>